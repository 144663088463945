

export default function UnrealProjectsComp()
{     
  return ( 
    <div className="workexp_comp">
        
      <div className="workexp_section">
        
        <div>
          <center><hr></hr></center>
            <h1><center><a href="https://www.youtube.com/@BeeBlueBubble" target="_blank" rel="noopener noreferrer" className="linkCompany">Unreal Engine Tutorials</a>  (C++ & Blueprints)</center></h1>
            <center>
            <h2><p>I have extensive expertise in Unreal 5 <b>(with both C++ and Blueprints)</b> and enjoy using the engine<br></br>		
              and sharing my passion for it so much that I maintain a YouTube channel and regularly publish tutorials:<br></br>	</p></h2>  
              <h3><a href="https://www.youtube.com/@BeeBlueBubble" target="_blank" rel="noopener noreferrer" class="linkProjects">https://www.youtube.com/@BeeBlueBubble</a></h3>
              
              <h2><p>Visit my Github profile
                <a href="https://github.com/bluebubblebee" target="_blank" rel="noopener noreferrer" class="linkProjects"> (https://github.com/bluebubblebee) </a> 
                to check my Unreal & Unity Projects	</p></h2>  
            </center>    
        </div>
        
        <div className="workexp_videos">			 
              <iframe src="https://www.youtube.com/embed/i9ONbMYobjg?si=qsUDzWB35sK-rfIc" title="How to create Utility Widgets" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>			
              <iframe src="https://www.youtube.com/embed/5r9TTyCi55I?si=YBFonJbwHXOkJz8y" title="Save & Load System" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>		             						 
        </div>  
        <div className="workexp_videos">		
          <iframe src="https://www.youtube.com/embed/eLAYQAFKzRo?si=o5qRarq0SilPLrhF" title="How to use Interfaces" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>    
        
      </div> 
      {/*
      <div className="workexp_section">
          <center><hr></hr></center>  
          
          <div className="workexp_header">
            <h1><center><a href="https://store.steampowered.com/app/1340380/Kitori_Academy/" rel="noopener noreferrer" target="_blank" className="linkCompany">Kitori Academy:</a> Lead Developer: Unreal Engine 5</center></h1>
            <h2><center>- Simulation Game - PC and Consoles (Ongoing Development)</center></h2>
          </div> 
          
          <div className="workexp_videos">				 
              <iframe src="https://www.youtube.com/embed/T5ZS6LrMBAY?si=7KByZD4G3U2A8vJ2" title="Kitori Academy" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
          </div>
          
          <div className="workexp_desc">
            <center>              
              <p><b>At Cubenary</b>, <b>I lead</b> the development of the immersive life simulator <b>"Kitori Academy"</b> <br></br>
                where players embark on adventures as wizard apprentices alongside their feline companions.<br></br></p>
                
                <p>My responsibilities involve <b>collaborating closely with the art and design team</b><br></br>	
                 to bring the core gameplay loop and integral game systems to fruition. <br></br>
                I oversee the implementation of key mechanics such as farming, quests, inventory systems, and the progressive wizard training arc.
                </p>	
                <p>	
                Additionally, I drive the development of the NPC AI systems, dialogue systems, <br></br>
                and essential features like the saving system, menus, and testing tools.<br></br>
               </p>	            
            </center>
          </div>      
          
      </div>  
      
      <div className="workexp_section">
          <center><hr></hr></center>  
          
          <div className="workexp_header">
            <h1><center><a href="https://store.steampowered.com/app/1125360/Fantasy_Little_Jobs/" target="_blank" rel="noopener noreferrer" className="linkCompany">Fantasy Little Jobs:</a> Unreal Developer: Unreal Engine 4 </center></h1>
            <h2><center>- Simulation VR Game - PC - Published on Steam in 2019</center></h2>
          </div> 
          
          <div className="workexp_videos">		 
              <iframe src="https://www.youtube.com/embed/1CG8xHGM0qU?si=svhqQhr48e9XGrW" title="Fantasy Little Jobs" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                    
          </div>
          
          <div className="workexp_desc">
            <center>              
              <p><b>In "Fantasy Little Jobs",</b> a VR game experience tailored for the Vive Headset, <br></br>players immerse themselves in a world of mini-games while tending customers in a shop set in the medieval era.</p>
              <p>My role included the implementation of player interactions with objects within the VR environment, <br></br> as well as developing engaging mini-games such as the cooking Experience and the archery challenge.</p>          
            </center>
          </div>      
          
      </div> */}
      
    </div>    
  );
}