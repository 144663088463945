

import linkedinImg from '../../assets/images/linkedin.png';

export default function ContactComp()
{     
  return (       
        
    <div className="workexp_comp">       
      
      <div className="workexp_title">
        <center>
          <h2><p>Thank you for taking the time to review my professional resume</p> </h2>          
          <h2><p>Don't hesitate to contact me at:</p> </h2>  
          <h2><p class="mail">beatriz.s.g86@gmail.com </p> </h2> 
          <p><h3><a href="https://www.linkedin.com/in/beatrizsanchezgonzalez" target="_blank" rel="noopener noreferrer" class="linkCompany">
            <img src={linkedinImg} alt="linkedIn"/></a></h3> </p>     
        </center>
      </div> 
          
    </div>
  );
}