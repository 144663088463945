

export default function EducationComp()
{     
  return (       
        
      <div className="workexp_comp">       
        
        <div className="workexp_title">
          <center>
            <h2><p><b>University Degree:</b> Design and Development of Videogames (Graduated 2014)<br></br>
            <b>Location:</b> UCJC - Camilo José Cela Universite. <b>Madrid, Spain</b>
            </p> </h2>          
            <h2><p><b>Certificate of Higher Education (HNC):</b> Technician in Multi-platform Applications Development (Graduated 2008)<br></br>
              <b>Location:</b> IES. Jose Luis Sampedro. <b>Madrid, Spain</b>
            </p> </h2>          
          </center>
        </div> 
          
    </div>  
  );
}