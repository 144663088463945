
import { useState } from 'react';

import MenuButton from './MenuButton.jsx'
import WorkExpComp from '../TabSections/WorkExperienceComp.jsx'
import UnrealProjectsComp from '../TabSections/UnrealProjectsComp.jsx'
import EducationComp from '../TabSections/EducationComp.jsx'
import ContactComp from '../TabSections/ContactComp.jsx'

export default function Content()
{ 
    
    const [ selectedTopic, setSelectedTopic ] = useState('workexperience');
  
  function handleSelectTopic(selectedMenuButton) 
  { 
    setSelectedTopic(selectedMenuButton); 
    console.log(selectedMenuButton); 
  } 
  
  function getTabContent(selectedTopic) 
  {
    switch (selectedTopic) 
    {
      case 'workexperience':
        return <WorkExpComp />;
      case 'unrealprojects':
        return <UnrealProjectsComp />;
      case 'education':
        return <EducationComp />;
      case 'contact':
        return <ContactComp />;
      default:
        return null;
    }
  }
  
  let tabContent = getTabContent(selectedTopic);    
    
return ( 
    <main>
    <section id="menusections">
        <menu className="horizontal-menu">
        <MenuButton isSelected={selectedTopic === 'workexperience'} onSelect={() => handleSelectTopic('workexperience')}>Work Experience</MenuButton>
        <MenuButton isSelected={selectedTopic === 'unrealprojects'}  onSelect={() => handleSelectTopic('unrealprojects')}>Unreal Engine Projects & Tutorials</MenuButton>
        <MenuButton isSelected={selectedTopic === 'education'}  onSelect={() => handleSelectTopic('education')}>Education</MenuButton>
        <MenuButton isSelected={selectedTopic === 'contact'}  onSelect={() => handleSelectTopic('contact')}>Contact</MenuButton>
        </menu>
    </section>
    {tabContent}       
    
    </main>          
   
    
);
    
}