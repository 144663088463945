import "./Header.css";


// Component Header
export default function Header()
{     
  return (
    <section>  
      <header>        
          <h1>Beatriz Sanchez - Game Developer</h1> 
          <p>Dedicated team member and talented programmer</p> 
        </header>
    </section>  
  );
}