import "./Menu.css";

// Component Header
export default function MenuButton({children, onSelect, isSelected })
{ 
    return( 
        <li>
            <button className={isSelected ? 'active' : undefined} onClick={onSelect}>{children}</button>
        </li>
    ); 
}