
import './App.css';
import Header from './components/Header/Header.jsx'
import Content from './components/Menu/Content.jsx'

function App() {

  return (
    <> 
      <Header/> 
      <Content/>
    </>
  );
}

export default App;
