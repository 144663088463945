import "./WorkExperience.css";
import dungeonLegendsImag from '../../assets/images/DungeonLegends1920x1080.jpg'

export default function WorkExpComp()
{     
  return (      
    <div className="workexp_comp">       
      
        <div className="workexp_title">
          <center>
            <p><b>Quick learner</b> who enjoys writing organized and <b>well-structured</b> clean code. </p>
            <p> Patient and <b>dedicated</b> when it comes to problem-solving. </p>          
          </center>
          <center><hr></hr></center>
        </div>
        
        <div className="workexp_section">
          <div>
            <h1><center><a href="https://fitxr.com/" target="_blank" className="linkCompany" rel="noopener noreferrer">FitXR:</a> Senior Unity Developer - February 2019 - April 2024</center></h1>
            <h2><center>- VR Fitness Games - Meta Quest - PICO - </center></h2>      
          </div>
          
          <div className="workexp_videos">			 
              <iframe src="https://www.youtube.com/embed/6dnq6CzahSs" title="FitXR Trailer" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>											 
          </div>  
          
          <div className="workexp_desc">
            <center>              		
              <p>As a valued member of the FitXR VR fitness app team, <b>I played an integral role</b><br></br>				
              in shaping key aspects of the application, contributing to <b>its successful launch.</b><br></br></p>						
              <p>My responsibilities included working on core features, refining gameplay mechanics,<br></br>	 
              enhancing user interface, and developing our <b>initial motion capture tool</b> for dance content creation.</p>             
            </center>  
          </div>     
          
        </div>        
        
        <div className="workexp_section">
          <center><hr></hr></center>
          
          <div className="workexp_header">
            <h1><center><a href="https://www.goodcatchgames.com/" target="_blank" rel="noopener noreferrer" className="linkCompany">Good Catch Games:</a> Unity Developer - 2018 - 2019</center></h1>
            <h2><center>- F2P Games - IOS - Android</center></h2>
          </div> 
          
          <div className="workexp_videos">			 
              <iframe src="https://www.youtube.com/embed/TyxFqgXQoxc" title="Sounds Stupid" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <iframe src="https://www.youtube.com/embed/cgrtYGYV5nc" title="Simon's Cat Dash" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>              	             
          </div>         
       
          <div className="workexp_desc">
            <center>              
              <p><b>At Good Catch</b>, I led the development and successful launch of their <b>premier</b> party game for iOS and Android, <b>"Sounds Stupid".</b><br></br>
                My responsibilities included implementing <b>core gameplay mechanics</b>, integrating user interface (UI) <br></br>
                and in-app purchases (IAP). Cultivating <b>close collaboration</b> with artists and designers.
                </p>		
                <p>Additionally, I provided extensive support for <b>"Simon's Cat Dash"</b>, managing the implementation<br></br>
                of new content and game mechanics outlined in its roadmap.</p>	            
            </center>
          </div>          
        </div>
        
        <div className="workexp_section">
          <center><hr></hr></center>
          
          <div className="workexp_section">
            <h1><center><a href="http://www.adsreality.com/" target="_blank" rel="noopener noreferrer" className="linkCompany">ADSReality:</a> Senior Unity Developer - 2016 - 2018</center></h1>
            <h2> <center>- Mobile Games - AR Apps & VR Interactive Experiences</center></h2>
          </div>
          
          <div className="workexp_videos">
            <center>					 
              <iframe src="https://www.youtube.com/embed/qizg-TRMpkI" title="Ads Reality Showreel 1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>	
              <iframe src="https://www.youtube.com/embed/c9jm7zkOAZw" title="Ads Reality Showreel 2" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <br></br> 
              <iframe src="https://www.youtube.com/embed/dQeSMvj5-tM" title="Tako Pergamon" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>	
            </center>            
          </div>
          
          <div className="workexp_desc">
            <center>
              <p>While at <b>ADS Reality</b>, I immersed myself in cutting-edge <b>AR and VR technologies</b>, <br></br> 
              dedicating my efforts to crafting immersive applications and interactive experiences for diverse clients. 			
              </p>
              <p>My key contributions included several impactful branding AR apps, such as <br></br> 
              <b>Heathrow Bears, Around the World with Mr Adventure, and Haliborange.</b></p>
              <p>Additionally, I was an essential developer for various VR-immersed experiences, such as <br></br> 
              <b>"When Aliens Attack,"</b> which was designed specifically for the Vive Headset.</p>
            </center>
          </div>
          
          
          <div className="workexp_section">
            <center><hr></hr></center>
            <div className="workexp_header">
              <h1><center><a href="http://codigames.com/" target="_blank" rel="noopener noreferrer" className="linkCompany">Codigames:</a> Senior Unity Developer - 2015</center></h1>
              <h2><center>- F2P Games - IOS - Android</center></h2>
            </div> 
          
            <div className="workexp_videos">	
                <a href="http://codigames.com/portfolio/dungeon-legends/" target="_blank" rel="noopener noreferrer">
                  <img src={dungeonLegendsImag} alt="Dungeon Legends"/>;       
                </a>
              {/*<iframe src="https://www.youtube.com/embed/hBankeGEzSs" title="Dungeon Legends" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>	*/}                                         
            </div>
          
            <div className="workexp_desc">
              <center>
                <p><b>Codigames</b> marked a significant advancement in my career,
                contributing to the <br></br>continuous success of <b>"Dungeon Legends,"</b> 
                 a Free-to-Play mobile title boasting 350K Monthly Active Users. </p>
                 <p>My responsibilities included implementing <b>crucial features like the Explorer and the Blacksmith</b> <br></br> 
                 enabling automated resource gathering and weapon improvement.</p>
                 <p>Additionally, I played an essential role in developing critical <b>client networking features,</b><br></br>
                 explicitly focusing on advancing the progress tracking of the Main Character. 	
                </p>
              </center>
            </div>
          </div>
          
          
          <div className="workexp_section">
            <center><hr></hr></center>
            <div className="workexp_header">
              <h1><center><a href="http://www.arloon.com/" target="_blank" rel="noopener noreferrer" className="linkCompany">Arloon:</a> Unity Developer - 2014 - 2015</center></h1>
              <h2><center>- Augmented Reality Educational Apps - IOS - Android</center></h2>
            </div> 
          
            <div className="workexp_videos">		 
                <iframe src="https://www.youtube.com/embed/FHPhDdb668w?list=PLY6yqGgn9VZFvRcyyPwAkQX1RB6q8R0bb" title="Arloon" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>	                                         
            </div>
          
            <div className="workexp_desc">
              <center>
                <p><b>At Arloon</b>, I enhanced my technical skills by crafting immersive educational experiences<br></br>
                through Augmented Reality apps, combining <b>Vuforia SDK and Unity 3D.</b> <br></br>                
                </p>
                <p> 	
                Among my significant projects, I was the primary developer for the <b>Anatomy and Solar System AR Mobile Apps.</b><br></br> 
                Furthermore, I played a crucial role in the ongoing maintenance and bug-fixing for<br></br> <b>Plants and Chemistry AR Mobile Apps.</b>
                </p>
              </center>
            </div>
          </div>
          
        </div>
    </div>
  );
}